import * as prismic from '@prismicio/client';
import * as prismicNext from '@prismicio/next';
import { Locale, PrismicLocales } from 'ui/config/locales';
import config from '../slicemachine.config.json';
import { AllDocumentTypes } from 'prismic/prismicio-types';

/**
 * The project's Prismic repository name.
 */
export const repositoryName = config.repositoryName;

/**
 * A list of Route Resolver objects that define how a document's `url` field is resolved.
 *
 * {@link https://prismic.io/docs/route-resolver#route-resolver}
 */
// TODO: Update the routes array to match your project's route structure.
const routes: prismic.ClientConfig['routes'] = [
  {
    type: 'page_v2',
    uid: 'home',
    lang: '*',
    path: '/',
  },
  {
    type: 'page_v2',
    uid: 'services',
    lang: '*',
    path: '/services',
  },
  {
    type: 'page_v2',
    uid: 'search',
    lang: '*',
    path: '/search',
  },
  {
    type: 'page_v2',
    uid: 'press',
    lang: '*',
    path: '/press',
  },
  {
    type: 'page_v2',
    uid: 'hire-developers',
    lang: PrismicLocales.en,
    path: '/hire-developers',
  },
  {
    type: 'page_v2',
    uid: 'hire-engineers',
    lang: PrismicLocales.en,
    path: '/hire-engineers',
  },
  {
    type: 'page_v2',
    uid: 'blog-home',
    lang: '*',
    path: '/blog',
  },
  {
    type: 'page',
    uid: 'customer_review',
    lang: PrismicLocales.en,
    path: '/review/customer',
  },
  {
    type: 'page',
    lang: PrismicLocales.en,
    path: '/:uid',
  },
  {
    type: 'page',
    lang: PrismicLocales.es,
    path: `/${Locale.Spanish}/:uid`,
  },
  {
    type: 'page',
    lang: PrismicLocales.pt,
    path: `/${Locale.Portuguese}/:uid`,
  },
  {
    type: 'company',
    lang: '*',
    path: '/company',
  },
  {
    type: 'resources',
    lang: '*',
    path: '/resources/:uid',
  },
  {
    type: 'kb',
    lang: PrismicLocales.en,
    path: '/kb/:uid',
  },
  {
    type: 'kb',
    lang: PrismicLocales.es,
    path: `/${Locale.Spanish}/kb/:uid`,
  },
  {
    type: 'kb',
    lang: PrismicLocales.pt,
    path: `/${Locale.Portuguese}/kb/:uid`,
  },
  {
    type: 'service_v2',
    lang: '*',
    path: '/services/:uid',
  },
  {
    type: 'case_studies',
    lang: '*',
    path: '/services/blog/:uid',
  },
  {
    type: 'service_campaign',
    lang: '*',
    path: '/services/campaign/:uid',
  },
  {
    type: 'hire_ad',
    lang: '*',
    path: '/lp/hire/:uid',
  },
  {
    type: 'service_ad',
    lang: '*',
    path: '/lp/services/:uid',
  },
  {
    type: 'service_main_ad',
    lang: '*',
    path: '/services/lp/:uid',
  },
  {
    type: 'hire',
    lang: '*',
    path: '/hire/:uid',
  },
  {
    type: 'job',
    lang: PrismicLocales.en,
    path: '/jobs/:uid',
  },
  {
    type: 'job',
    lang: PrismicLocales.es,
    path: `/${Locale.Spanish}/jobs/:uid`,
  },
  {
    type: 'job',
    lang: PrismicLocales.pt,
    path: `/${Locale.Portuguese}/jobs/:uid`,
  },
  {
    type: 'remote_job_page',
    lang: PrismicLocales.en,
    path: '/remote-developer-jobs/:uid',
  },
  {
    type: 'remote_job_page',
    lang: PrismicLocales.es,
    path: `/${Locale.Spanish}/remote-developer-jobs/:uid`,
  },
  {
    type: 'remote_job_page',
    lang: PrismicLocales.pt,
    path: `/${Locale.Portuguese}/remote-developer-jobs/:uid`,
  },
  {
    type: 'interview_question_page',
    lang: PrismicLocales.en,
    path: '/interview-questions/:uid',
  },
  {
    type: 'interview_question_page',
    lang: PrismicLocales.es,
    path: `/${Locale.Spanish}/interview-questions/:uid`,
  },
  {
    type: 'interview_question_page',
    lang: PrismicLocales.pt,
    path: `/${Locale.Portuguese}/interview-questions/:uid`,
  },
  {
    type: 'job_description_page',
    lang: '*',
    path: '/job-description/:uid',
  },
  {
    type: 'review',
    lang: PrismicLocales.en,
    path: '/review/:uid',
  },
  {
    type: 'resume',
    lang: PrismicLocales.en,
    path: '/resume/:uid',
  },
  {
    type: 'cloud',
    lang: PrismicLocales.en,
    path: '/cloud/:uid',
  },
  {
    type: 'blog',
    lang: '*',
    path: '/blog/:uid',
  },
  {
    type: 'blog_category',
    lang: '*',
    path: '/blog/category/:uid',
  },
  {
    type: 'use_case',
    lang: '*',
    path: '/use-case/:uid',
  },
  {
    type: 'case_study_v2',
    lang: '*',
    path: '/case-study/:uid',
  },
  {
    type: 'job_v2',
    lang: PrismicLocales.en,
    path: '/jobs/v2/:uid',
  },
];

export const linkResolver: prismic.LinkResolverFunction = doc => {
  if (doc.type === 'hire_location_specific') {
    return `/hire/${doc.uid.split('__').join('/')}`;
  }
  if (doc.type === 'customer_lp') {
    return `/lp/${doc.uid}`;
  }
  if (doc.type === 'top_jobs') {
    return `/${doc.uid.split('__').join('/')}`;
  }
  return null;
};

// interface ExtendedClient

function wait(delay) {
  return new Promise(resolve => setTimeout(resolve, delay));
}

function getByUIDModified(documentType, uid, params, triesLeft = 1, client) {
  function onError(error) {
    // console.log('retrying ', triesLeft)
    if (triesLeft < 0) {
      /* eslint-disable no-console */
      console.log('Error Message -> ', error.message);
      throw error;
    }

    return wait(100).then(() => {
      return getByUIDModified(documentType, uid, params, -1, client);
    });
  }
  return client.getByUIDModified(documentType, uid, params).catch(onError);
}
/**
 * Creates a Prismic client for the project's repository. The client is used to
 * query content from the Prismic API.
 *
 * @param config - Configuration for the Prismic client.
 */
export const createClient = (config: prismicNext.CreateClientConfig = {}) => {
  const client = prismic.createClient(repositoryName, {
    routes,
    ...config,
  }) as {
    getByUIDModified: (...parameters: Parameters<typeof client.getByUID>) => ReturnType<typeof client.getByUID>;
  } & prismic.Client<AllDocumentTypes>;

  prismicNext.enableAutoPreviews({
    client,
    previewData: config.previewData,
    req: config.req,
  });
  client.getByUIDModified = client.getByUID;
  client.getByUID = function (documentType, uid, params) {
    console.log("__PRISMIC_API_CALL__")
    return getByUIDModified(documentType, uid, params, 1, client);
  };

  return client;
};
