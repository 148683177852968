import { ChangeEventHandler, FormEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import classNames from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import SearchIcon from 'ui/svgs/library/SearchIcon';
import CloseIcon from 'ui/svgs/library/CloseIcon';
import CloseCircleIcon from 'ui/svgs/library/CloseCircleIcon';
import ArrowForward from 'ui/svgs/library/ArrowForward';
import { Button } from '../Button';
import { CustomLink } from '../CustomLink';
import SearchResult from '../SearchResult';
import { SearchResponse } from '../../../../apps/web/service/elasticsearch';
import styles from './Search.module.scss';

type SearchBarProps = {
  defaultValue?: string;
  onSubmit?: (query: string) => void;
  getSuggestions?: (query: string) => Promise<string[]>;
  getQuickResults?: (query: string) => Promise<SearchResponse>;
}

export default function SearchBar({ defaultValue, onSubmit, getSuggestions, getQuickResults }: SearchBarProps) {
  const ref = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(defaultValue || '');
  const [suggestions, setSuggestions] = useState([]);
  const [results, setResults] = useState<SearchResponse['results']>();
  const [focused, setFocused] = useState(false);
  const [position, setPosition] = useState(-1);
  const [open, setOpen] = useState(false);
  const { t, lang } = useTranslation('search');
  const searchData: any = t('.', {}, { returnObjects: true });
  const router = useRouter();

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.target.value);
  };

  const debounceResults = useMemo(() => {
    return debounce(handleChange, 250);
  }, []);

  useEffect(() => {
    return () => {
      debounceResults.cancel();
    };
  });

  useEffect(() => {
    if (value.length >= 3) {
      // getSuggestions?.(value).then(setSuggestions);
      getQuickResults(value).then((response) => setResults(response.results));

    } else {
      // setSuggestions([]);
      setResults([]);
    }
  }, [value, getSuggestions, getQuickResults]);

  useEffect(() => {
    setPosition(-1);
  }, [suggestions]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    if (e.currentTarget.q.value) {
      onSubmit?.(e.currentTarget.q.value);
      setOpen(false);
      setValue('');
    }
  };

  return (
    <>
      {!router.pathname.startsWith('/search') && (
        <Button className={classNames(styles.searchButton, open ? styles.searchButtonActive : '')} onClick={() => setOpen(open => !open)}>
          <SearchIcon />
        </Button>
      )}

      {open && (
        <div className={styles.backdrop} onClick={() => setOpen(false)}>
          <div className={classNames('wide-container', styles.modal)} onClick={(e) => e.stopPropagation()}>
            <div className={styles.closeButtonContainer}>
              <Button className={styles.closeButton} onClick={() => {
                setOpen(false);
                setValue('')
              }}>
                <CloseIcon />
                <span className={styles.closeButtonLabel}>{searchData.close_button_text}</span>
              </Button>
            </div>

            <form className={styles.root} onSubmit={handleSubmit}>
              <div className={styles.inputContainer}>
                <input
                  ref={ref}
                  name="q"
                  autoFocus
                  defaultValue={defaultValue}
                  onChange={debounceResults}
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  autoComplete="off"
                  placeholder={searchData.placeholder_text}
                  // onKeyDown={(e) => {
                  //   if (['Tab', 'ArrowUp', 'ArrowDown'].includes(e.key)) e.preventDefault();

                  //   if (suggestions.length === 0) return;

                  //   if (e.key === 'Tab') {
                  //     setValue(suggestions[position < 0 ? 0 : position]);
                  //     setPosition(-1);
                  //   } else if (e.key === 'Enter') {
                  //     setValue(position < 0 ? value : suggestions[position]);
                  //     onSubmit?.(position < 0 ? value : suggestions[position]);
                  //     setPosition(-1);
                  //     ref.current?.blur();
                  //   } else if (e.key === 'ArrowDown') {
                  //     setPosition((position + 1) % suggestions.length);
                  //   } else if (e.key === 'ArrowUp') {
                  //     setPosition((position - 1 < 0) ? suggestions.length - 1: position - 1);
                  //   } else if (e.key === 'Escape') {
                  //     ref.current?.blur();
                  //     setOpen(false);
                  //   }
                  // }}
                />

                <Button className={styles.inputButton} type="submit" onClick={() => {}}>
                  <SearchIcon />
                </Button>

                <Button className={styles.inputButton} type="reset" onClick={() => { ref.current?.focus(); setValue(''); } }>
                  <CloseCircleIcon />
                </Button>
              </div>

              {/* Quick Results */}
              {value.length >= 3 && results.length > 0 && (
                <div className={styles.searchResultsContainer}>
                  {results.map(result => (
                    <SearchResult key={result.id} result={result} variant="light" />
                  ))}
                </div>
              )}

              {/* Popular searches */}
              {!(value.length >= 3 && results.length > 0) && (
                <div className={styles.popularSearchesContainer}>
                  <div className={styles.popularSearchesTitle}>{searchData.popular_search_title}</div>
                  <div>
                    {searchData.popular_searches.map(({ search_term }, index) => (
                      <CustomLink href={`/search/?q=${encodeURIComponent(search_term)}`} key={index}>
                        <a className={styles.popularSearchTermRow} onClick={() => setOpen(false)}>
                          <div>{search_term}</div>
                          <ArrowForward />
                        </a>
                      </CustomLink>
                    ))}
                  </div>
                </div>
              )}

              {/* Suggestions */}
              {/* {false && focused && value.length >= 3 && !!suggestions.length && (
                <div className={styles.suggestions}>
                  {suggestions.map((suggestion, index) => (
                    <div
                      className={index === position ? styles.active : ''}
                      onClick={() => {
                        setValue(suggestion);
                        onSubmit?.(suggestion);
                        ref.current?.blur();
                      }}
                      onMouseDown={e => e.preventDefault()}
                    >
                      {suggestion}
                    </div>
                  ))}
                </div>
              )} */}
            </form>
          </div>
        </div>
      )}
    </>
  )
}
