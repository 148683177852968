import cn from 'classnames';
import { FC, PropsWithChildren } from 'react';
import { Headline } from 'ui/components/Headline';
import { SectionProps } from './section.interface';
import styles from './section.module.scss';

export const Section: FC<PropsWithChildren<SectionProps>> = props => {
  const {
    bg = 'white',
    textAlign,
    title,
    titleClassName,
    watchVideoBtn,
    headlineMargin = 'md',
    subTitle = '',
    headlineColumnOneSize,
    textColor,
    className,
    pad = 'md',
    topPad,
    botPad,
    headlineSecondColumn,
    containerSize = 'normal',
    containerClass,
    headlineTag,
    headlineSize = 'md',
    headlineType = 'normal',
    children,
    isSubtitleHTML,
    overflowHidden = false,
    subTitleClassName,
    noContainerPadding = false,
    applySubtitleHTMLClassName = false,
    ...rest
  } = props;
  const cns = cn(
    styles.frame,
    { [styles.frameOverflowHidden]: overflowHidden },
    styles[`bg-${bg}`],
    styles[`color-${textColor}`],
    styles[`frame-${pad}`],
    styles[`frame-top-${topPad}`],
    styles[`frame-bot-${botPad}`],
    { [styles[`frame-t-align-${textAlign}`]]: textAlign },
    className && { [className]: className },
  );
  return (
    <section {...rest} className={cns}>
      <div
        className={cn(
          'container',
          {
            'wide-container': containerSize === 'wide',
            [styles.noContainerPadding]: noContainerPadding
          },
          containerClass,
        )}
      >
        <div
          className={cn(
            styles.headline,
            styles[`headline-align-${textAlign}`],
            [styles[`headline-margin-${headlineMargin}`]],
          )}
        >
          <Headline
            title={title}
            subTitle={subTitle}
            headlineColumnOneSize={headlineColumnOneSize}
            headlineSecondColumn={headlineSecondColumn}
            watchVideoBtn={watchVideoBtn}
            tag={headlineTag}
            size={headlineSize}
            type={headlineType}
            className={titleClassName}
            isSubtitleHTML={isSubtitleHTML}
            subTitleClassName={subTitleClassName}
            applySubtitleHTMLClassName={applySubtitleHTMLClassName}
          />
        </div>
        <>{children}</>
      </div>
    </section>
  );
};
