import { FC, PropsWithChildren } from 'react';
import { EnvironmentDomains } from 'ui/config/environment';
import dynamic from 'next/dynamic';

const PrismicPreviewDynamic = dynamic(() => import('./previewcore'), {
  loading: () => <p>Loading...</p>,
});

export const PrismicPreview: FC<PropsWithChildren> = props => {
  const { children } = props;
  if (process.env.NEXT_PUBLIC_SUBDOMAIN !== EnvironmentDomains.Dev) {
    return <>{children}</>;
  }

  return <PrismicPreviewDynamic>{children}</PrismicPreviewDynamic>;
};
