import { Locale } from 'ui/config/locales';
import { isProd as isProdFunc, isStaging } from 'ui/utils/helpers/env-helper';

export const parseQuery = (queryStr: string = ''): Record<string, string> => {
  const queryObject: Record<string, string> = {};
  const queryParams = queryStr.split('&');
  queryParams.forEach(param => {
    const [key, value] = param.split('=');
    queryObject[key] = value;
  });
  return queryObject;
};

export const stringifyQuery = (queryObj: Record<string, string>): string => {
  return Object.entries(queryObj)
    .filter(([key]) => !!key)
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join('&');
};

export const isFallback = () => {
  // Commenting the if condition, to make the revalidation works for new pages as well which were not part of the build
  // if (isProdFunc() && !isStaging()) {
  //   return false;
  // }
  return true;
};
