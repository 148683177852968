var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"4ae0c03a7436a536a9f3d472eab1b009e341cb56"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
const { version } = require('../../package.json');

Sentry.init({
  dsn: 'https://e0b01dbe7e47c8b945ffe06539d2c7c7@o311810.ingest.sentry.io/4505822656266240',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.25,
  environment: process.env.NEXT_PUBLIC_IS_PROD
    ? 'production'
    : process.env.NEXT_PUBLIC_SUBDOMAIN === 'staging'
    ? 'staging'
    : process.env.DEPLOYMENT_ENVIRONMENT || process.env.NEXT_PUBLIC_DEPLOYMENT_ENVIRONMENT || 'development',
  enabled:
    process.env.DEPLOYMENT_ENVIRONMENT !== 'localhost' ||
    process.env.NEXT_PUBLIC_DEPLOYMENT_ENVIRONMENT !== 'localhost',
  release: `turingcom@${version}`,
  allowUrls: [/https?:\/\/((www)\.)?turing\.com/],
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  ignoreUrls: ['localhost', '127.0.0.1'],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
